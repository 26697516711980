import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { post } from "./cupla";

let Buffer = require("buffer/").Buffer; // NOTE: trailing slash required

export enum LoginState {
    LoggedOut = 0,
    LoggingIn,
    LoggedIn,
    LoggingOut
}

/** The App Engine */
export class AppEngine {
    loginState: LoginState = LoginState.LoggedOut;
    token: string = "";

    api: AxiosInstance = axios.create({
        baseURL: window.config.API
    });

    constructor() {
        //this.token = new URLSearchParams(window.location.search).get("token");
        if (!this.token) {
            this.token = localStorage.getItem(window.config.Token);
        }
        console.log("token:", this.token);
        if (this.token) {
            this.login();
        }
    }

    /** Helper method to make REST API request. Returns [json,error] */
    async request(
        config: AxiosRequestConfig,
        debugMsg?: string,
        isAuthenticationRequest?: boolean
    ): Promise<[any, any]> {
        try {
            config = { responseType: "json", ...config };
            if (this.token) {
                config.headers = {
                    Authorization: "Bearer " + this.token
                };
            }
            let response = await this.api.request(config);
            if (response.status >= 200 && response.status <= 299) {
                if (debugMsg && response.data) {
                    console.log(debugMsg, response.data);
                } else if (response.data) {
                    console.log(response.data);
                }
                return [response.data, null];
            } else if (response.status === 401 && !isAuthenticationRequest) {
                // Unauthorized (and not actual login/logout) => check if our user credential is still valid
                let error = await this.login();
                if (error) {
                    // Lost user authorization => logout
                    await this.logout();
                }
                // here in both cases continue to return original request error
            }
            return [null, response.status];
        } catch (error: any) {
            let msg = error.message;
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                let s = error.response.data.message.trim();
                if (s) {
                    msg += ": " + s;
                }
            }
            if (debugMsg) {
                console.log(debugMsg, msg);
            } else {
                console.log(msg);
            }
            return [null, msg];
        }
    }

    getLoginState() {
        return this.loginState;
    }

    async login(password?: string) {
        this.loginState = LoginState.LoggingIn;
        let [response, error] = await this.request(
            {
                method: "post",
                url: "users/login",
                data: { password }
            },
            "login",
            true
        );
        post(() => window.app.update());
        if (response && response.token) {
            // Successful login
            this.token = response.token;
            if (password) {
                localStorage.setItem(window.config.Token, this.token);
            }
            this.loginState = LoginState.LoggedIn;
            return null;
        } else {
            // Login failed
            this.doLogout();
            return error;
        }
    }

    /** Logs out user */
    async logout() {
        this.loginState = LoginState.LoggingOut;
        await this.request({ url: "users/logout" }, "logout");
        this.doLogout();
    }

    doLogout() {
        if (localStorage.getItem(window.config.Token) === this.token) {
            localStorage.removeItem(window.config.Token);
        }
        this.token = "";
        this.loginState = LoginState.LoggedOut;
    }

    async pullFile(id: string) {
        let [response, error] = await this.request({
            responseType: "arraybuffer",
            url: "/files/" + id
        });
        if (error) {
            return null;
        }
        return Buffer.from(response).toString("base64");
    }
}
