// Application config
let config = {
    Path: "",
    Token: "ohje.token",
    API: "https://ohje.rools.fi:5502"
    //API: "http://localhost:5502"
    //API: "http://192.168.1.118:5502"
    //API: "http://192.168.1.137:5502"
};

// NOTE: this file is used by both node.js (development proxy setupProxy.js) and react app
if (typeof window !== "undefined") {
    window.config = config;
} else {
    module.exports = config;
}
