import React from "react";
import LogoImage from "./img/logo.png";

export default class SplashPage extends React.Component {
    render() {
        return (
            <div
                className="Page FlexColumn"
                style={{ height: "100%", background: "var(--green)" }}
            >
                <div style={{ flex: "2 2 auto" }} />
                <img
                    src={LogoImage}
                    style={{
                        flex: "1 1 auto",
                        maxWidth: "60%",
                        alignSelf: "center",
                        objectFit: "contain",
                        outline: 0
                    }}
                    alt="ROOLS"
                />
                <div style={{ flex: "3 3 auto" }} />
            </div>
        );
    }
}
