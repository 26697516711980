import React from "react";
import LogoImage from "./img/logo.png";

export default class EmptyPage extends React.Component {
    render() {
        return (
            <div
                className="Page FlexColumn"
                style={{
                    height: "100%",
                    alignItems: "center",
                    background: "var(--green)"
                }}
            >
                <div
                    className="FlexRow"
                    style={{
                        margin: "30px 0",
                        alignItems: "center"
                    }}
                >
                    <img
                        src={LogoImage}
                        style={{
                            height: "50px",
                            marginRight: "2px"
                        }}
                        alt="ROOLS"
                    />
                    <div
                        style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "white"
                        }}
                    >
                        ROOLS
                    </div>
                </div>

                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: 600,
                        color: "white"
                    }}
                >
                    Lue ohjeen QR-koodi
                </div>
            </div>
        );
    }
}
