import React, { CSSProperties } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useNavigate,
    NavigateFunction
} from "react-router-dom";
import LoginPage from "./LoginPage";
import { AppEngine, LoginState } from "./AppEngine";
import { currentTime, post, remove, Time } from "./cupla";
import "./Config";
import Dialog from "./Dialog";
import SplashPage from "./SplashPage";
import FilePage from "./FilePage";
import EmptyPage from "./EmptyPage";

export default class App extends React.Component<{}> {
    page: React.Component;
    navigate: NavigateFunction;
    userActivity: number = 0;
    toasts: { time: Timestamp; elem: JSX.Element }[] = [];

    state: {
        dialogs: Dialog[];
        popup: React.ReactNode;
        toast: JSX.Element;
    } = {
        dialogs: [],
        popup: null,
        toast: null
    };

    constructor(props: {}) {
        super(props);

        if (window.app) {
            console.assert(false, "Please disable fast refresh");
        }
        window.app = this;
        if (window.eng) {
            return;
        }
        window.eng = new AppEngine();

        console.log(
            "App display=" +
                window.innerWidth +
                "x" +
                window.innerHeight +
                " mobile=" +
                this.isMobile() +
                " touch=" +
                this.isTouch()
        );
    }

    render() {
        return (
            <BrowserRouter basename={window.config.Path}>
                <div className="App">
                    <>
                        {window.eng.loginState === LoginState.LoggedIn ? (
                            <Routes>
                                <Route
                                    path="/:file"
                                    element={<Page clazz={FilePage} />}
                                />
                                <Route
                                    path="/"
                                    element={<Page clazz={EmptyPage} />}
                                />
                                <Route
                                    path="/logout"
                                    element={<Page clazz={LogoutPage} />}
                                />
                            </Routes>
                        ) : [
                              LoginState.LoggingIn,
                              LoginState.LoggingOut
                          ].includes(window.eng.loginState) ? (
                            <Page clazz={SplashPage} />
                        ) : (
                            <Page clazz={LoginPage} />
                        )}

                        {this.state.dialogs.map(dlg => dlg.modalElement)}
                        {this.state.popup}
                        {this.state.toast}
                    </>
                </div>
            </BrowserRouter>
        );
    }

    componentDidMount() {
        document.body.onkeyup = event => {
            let key = event.which || event.keyCode;
            for (let i = this.state.dialogs.length - 1; i >= 0; i--) {
                let dialog = this.state.dialogs[i];
                if ("onAppKeyUp" in dialog) {
                    dialog.onAppKeyUp(key);
                }
            }
            if (this.state.dialogs.length) {
                return;
            }
            if (this.page && "onAppKeyUp" in this.page) {
                (this.page as any).onAppKeyUp(key);
            }
        };
        window.onresize = () => {
            if (this.page && "onAppResize" in this.page) {
                (this.page as any).onAppResize();
            }
        };
    }

    update() {
        this.forceUpdate();
    }

    /** Opens the specified path
     *  NOTE: path begins with "/" or is full url
     *  NOTE: does not reload (except if opened to new tab)
     */
    openPath(
        path: string,
        newTab?: boolean /*=false*/,
        keepTokenAndTheme?: boolean /*=true*/
    ) {
        path = this.handlePath(path, keepTokenAndTheme);
        if (newTab) {
            // NOTE: must post for the new tab to get focus
            post(() => window.open(this.getFullUrl(path)));
        } else {
            if (this.navigate) {
                this.navigate(path);
            } else {
                // Navigate method not available
                (window as Window).location = this.getFullUrl(path);
            }
        }
    }

    /** Sets the path shown in browser url
     *  NOTE: path begins with "/"
     *  NOTE: does not change page
     */
    setPath(path: string, keepTokenAndTheme?: boolean /*=true*/) {
        path = this.handlePath(path, keepTokenAndTheme);
        if (this.navigate) {
            this.navigate(path, { replace: true });
        } else {
            // Navigate method not available, cannot change without reload
        }
    }

    handlePath(path: string, keepTokenAndTheme?: boolean /*=true*/) {
        path = path ? path : "";
        if (typeof keepTokenAndTheme === "undefined" || keepTokenAndTheme) {
            let params = new URLSearchParams(window.location.search);
            let token = params.get("token");
            if (token) {
                path += (path.includes("?") ? "&" : "?") + "token=" + token;
            }
            let theme = params.get("theme");
            if (theme) {
                path += (path.includes("?") ? "&" : "?") + "theme=" + theme;
            }
        }
        return path;
    }

    /** Gets full URL for specified path (without parameters)
     *  NOTE: path begins with "/" or is full url
     */
    getFullUrl(path?: string /*=current path*/) {
        if (!path) {
            path = window.location.pathname;
        }
        if (path.indexOf("://") >= 0) {
            return path;
        }
        return window.location.protocol + "//" + window.location.host + path;
    }

    isDialogOpen() {
        return this.state.dialogs.length > 0;
    }

    addDialog(dialog: Dialog) {
        this.setState({ dialogs: [...this.state.dialogs, dialog] });
    }

    removeDialog(dialog: Dialog) {
        let dialogs = this.state.dialogs;
        remove(dialogs, dialog);
        this.setState({ dialogs });
    }

    setPopup(popup: React.ReactNode) {
        this.setState({ popup });
    }

    toast(text: string) {
        if (text && String(text).length) {
            this.toasts.push({
                time: 0,
                elem: <div className="AppToast">{String(text)}</div>
            });
            this.handleToasts();
        }
    }

    handleToasts() {
        //! fade animation
        if (!this.toasts.length) {
            return;
        }
        let toast = this.toasts[0];
        if (!toast.time) {
            toast.time = currentTime();
            this.setState({ toast: toast.elem });
            post(() => this.handleToasts(), 3 * Time.Second);
        } else if (currentTime() >= toast.time + 3 * Time.Second) {
            this.toasts = this.toasts.slice(1);
            this.setState({ toast: null });
            this.handleToasts();
        }
    }

    /** Tells if running in mobile device (small vertical touch display) */
    isMobile() {
        return window.innerWidth <= 800 && this.isTouch();
    }

    /** Tells if running in touch based device (no mouse) */
    isTouch() {
        return "ontouchstart" in window;
    }

    isUserActivity() {
        return this.userActivity > 0;
    }

    beginUserActivity() {
        this.userActivity++;
    }

    endUserActivity() {
        if (this.userActivity > 0) {
            this.userActivity--;
        }
    }

    var(name: string) {
        return getComputedStyle(document.documentElement).getPropertyValue(
            name
        );
    }
}

/** Page wrapper */
function Page(props: any) {
    window.app.navigate = useNavigate();
    return (
        <props.clazz
            params={useParams()}
            ref={(e: React.Component) => (window.app.page = e)}
        />
    );
}

/** Logout page */
class LogoutPage extends React.Component {
    render() {
        return (
            <div
                style={{
                    height: "100%",
                    padding: "15px",
                    background: "var(--green)",
                    color: "white"
                }}
            >
                Logging out...
            </div>
        );
    }

    async componentDidMount() {
        await window.eng.logout();
        window.app.openPath("/");
        window.location.reload();
    }
}

/** Link component which opens to app path (without reload)
 * - Using real a element to enable copy link
 * - Note that a-href handles relative urls properly
 */
export class AppLink extends React.Component<{
    path: string;
    newTab?: boolean;
    style?: CSSProperties;
    children: React.ReactNode;
}> {
    render() {
        return (
            <a
                href={this.props.path}
                className="link"
                style={this.props.style}
                onClick={ev => {
                    ev.preventDefault();
                    window.app.openPath(
                        this.props.path,
                        this.props.newTab || ev.ctrlKey
                    );
                }}
            >
                {this.props.children}
            </a>
        );
    }
}
