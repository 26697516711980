import React from "react";
import { Button } from "react-bootstrap";
import { nbsp, post } from "./cupla";
import LogoImage from "./img/logo.png";

export default class LoginPage extends React.Component {
    password: HTMLInputElement;
    okButton: HTMLButtonElement;
    state: {
        note: string;
    } = {
        note: nbsp()
    };

    render() {
        return (
            <div
                className="Page FlexColumn"
                style={{
                    height: "100%",
                    alignItems: "center",
                    background: "var(--green)"
                }}
            >
                <div
                    className="FlexRow"
                    style={{
                        margin: "30px 0",
                        alignItems: "center"
                    }}
                >
                    <img
                        src={LogoImage}
                        style={{
                            height: "50px",
                            marginRight: "2px"
                        }}
                        alt="ROOLS"
                    />
                    <div
                        style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "white"
                        }}
                    >
                        ROOLS
                    </div>
                </div>

                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: 600,
                        color: "white"
                    }}
                >
                    Anna salasana
                </div>

                <input
                    ref={e => (this.password = e)}
                    type="password"
                    className="form-control"
                    onKeyUp={ev => this.onKeyUp(ev.which || ev.keyCode)}
                    style={{
                        width: "300px",
                        maxWidth: "calc(100% - 10px)",
                        margin: "10px 0 15px 0"
                    }}
                />

                <Button
                    ref={(e: any) => (this.okButton = e)}
                    variant="light"
                    onClick={() => this.onClickOk()}
                >
                    OK
                </Button>

                <div
                    style={{
                        color: "white",
                        marginTop: "10px"
                    }}
                >
                    {this.state.note}
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        if (window.location.pathname === "/logout") {
            post(() => window.app.setPath("", false), 100);
        }
        this.password.focus();
    }

    onKeyUp(key: number) {
        if (key === 13) {
            this.onClickOk();
        }
    }

    async onClickOk() {
        let pass = this.password.value.trim();
        if (!pass.length) {
            return;
        }
        this.setState({ note: nbsp() });
        this.password.disabled = true;
        this.okButton.disabled = true;
        let error = await window.eng.login(pass);
        if (error) {
            let note;
            error = error.toLowerCase();
            if (error.includes("network")) {
                note = "Verkko virhe";
            } else if (error.includes("code 5")) {
                note = "Palvelin virhe";
            } else {
                note = "Väärä salasana";
            }
            this.setState({ note });
        }
        if (this.okButton) {
            this.password.disabled = false;
            this.okButton.disabled = false;
        }
    }
}
