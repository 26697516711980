import React from "react";
import { Params } from "react-router-dom";
import LogoImage from "./img/logo.png";

export default class FilePage extends React.Component<{
    params: Readonly<Params<string>>;
}> {
    state: {
        file: string;
        error: boolean;
    } = {
        file: null,
        error: false
    };

    render() {
        return this.state.file ? (
            <div className="AppScroll" style={{ overflowY: "auto" }}>
                <img
                    src={"data:image/png;base64," + this.state.file}
                    style={{
                        width: "100%",
                        height: "auto"
                    }}
                />
            </div>
        ) : (
            <div
                className="Page FlexColumn"
                style={{
                    height: "100%",
                    alignItems: "center",
                    background: "var(--green)"
                }}
            >
                <div
                    className="FlexRow"
                    style={{
                        margin: "30px 0",
                        alignItems: "center"
                    }}
                >
                    <img
                        src={LogoImage}
                        style={{
                            height: "50px",
                            marginRight: "2px"
                        }}
                        alt="ROOLS"
                    />
                    <div
                        style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "white"
                        }}
                    >
                        ROOLS
                    </div>
                </div>

                {this.state.error ? (
                    <div
                        style={{
                            fontSize: "24px",
                            fontWeight: 600,
                            color: "white"
                        }}
                    >
                        Dokumenttia ei löydy
                    </div>
                ) : null}
            </div>
        );
    }

    async componentDidMount() {
        let id = this.props.params.file;
        if (!id || id.length === 0) {
            window.app.openPath("/");
            return;
        }
        let file = await window.eng.pullFile(id);
        console.log("file", file && file.length);
        this.setState({ file, error: !file });
    }
}
